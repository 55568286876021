import React from 'react';
import styles from './VersionToggle.module.css';

const VersionToggle = ({ version, onToggle }) => {
  return (
    <div className={styles.toggleContainer}>
      <button
        className={`${styles.toggleButton} ${version === 1 ? styles.active : ''}`}
        onClick={() => onToggle(1)}
      >
        Classic View
      </button>
      <button
        className={`${styles.toggleButton} ${version === 2 ? styles.active : ''}`}
        onClick={() => onToggle(2)}
      >
        Enhanced View (Beta)
      </button>
    </div>
  );
};

export default VersionToggle;