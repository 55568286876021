// src/components/Header.js

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaSun,
  FaMoon,
  FaUser,
  FaSignOutAlt,
  FaHome,
  FaFolder,
  FaUpload,
  FaCalendar,
  FaCreditCard,
  FaBars,
  FaTimes,
  FaBell,
  FaSearch,
} from "react-icons/fa";
import { AuthContext } from "./AuthProvider";
import TicTacToe from "./TicTacToe";
import "../styles/Header.css";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";

// Modal Component with Improved UI
const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  // Close modal on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Close modal on Escape key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleEsc);
    }
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true">
      <div className="modal-content" ref={modalRef}>
        <button
          className="modal-close"
          onClick={onClose}
          aria-label="Close modal"
        >
          <FaTimes />
        </button>
        {children}
      </div>
    </div>
  );
};

// Tooltip Component
const Tooltip = ({ children, content }) => (
  <div className="tooltip-container">
    {children}
    <span className="tooltip-text">{content}</span>
  </div>
);

const Header = () => {
  const { user, signOut, profilePicture } = useContext(AuthContext);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showTicTacToe, setShowTicTacToe] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // Persist dark mode preference
  useEffect(() => {
    const darkModePreference = localStorage.getItem("darkMode");
    if (darkModePreference === "enabled") {
      setIsDarkMode(true);
      document.body.classList.add("dark-mode");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      if (newMode) {
        document.body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "enabled");
      } else {
        document.body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "disabled");
      }
      return newMode;
    });
  };

  // Close dropdowns when clicking outside
  const accountMenuRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountMenuRef.current &&
        !accountMenuRef.current.contains(event.target)
      ) {
        setShowAccountMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className={`header ${isDarkMode ? "header-dark" : ""}`}>
      <div className="header-container">
        {/* Mobile Menu Toggle */}
        <button
          className="mobile-menu-toggle"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          aria-expanded={isMobileMenuOpen}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Left section with app title and dark mode toggle */}
        <div className="header-left">
          <Link to="/" className="app-title">
            Docket
            <span
              className="highlight"
              onClick={() => setShowTicTacToe(true)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") setShowTicTacToe(true);
              }}
              aria-label="Play Tic Tac Toe"
            >
              4
            </span>
            Me
          </Link>
          <div className="header-actions">
            <Tooltip content={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}>
              <button
                className="icon-btn"
                onClick={toggleDarkMode}
                aria-label={
                  isDarkMode
                    ? "Switch to Light Mode"
                    : "Switch to Dark Mode"
                }
              >
                {isDarkMode ? <FaSun /> : <FaMoon />}
              </button>
            </Tooltip>
          </div>
        </div>

        {/* Navigation links */}
        <AnimatePresence>
          {(isMobileMenuOpen || !isMobile) && (
            <motion.nav
              className={`header-nav ${
                isMobileMenuOpen ? "nav-open" : ""
              }`}
              aria-label="Main Navigation"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <ul className="nav-list">
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
                    <FaHome className="nav-icon" /> Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/cases" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
                    <FaFolder className="nav-icon" /> Cases
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/upload" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
                    <FaUpload className="nav-icon" /> Upload
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendar" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
                    <FaCalendar className="nav-icon" /> Calendar
                  </Link>
                </li>
              </ul>
            </motion.nav>
          )}
        </AnimatePresence>

        {/* Right section with search, notifications, and user info */}
        <div className="header-right">
          {/* Search Bar */}
          <div className="search-bar">
            <FaSearch className="search-icon" aria-hidden="true" />
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search"
            />
          </div>

          {/* Notifications */}
          <div className="notifications-container">
            <Tooltip content="Notifications">
              <button
                className="icon-btn notification-btn"
                onClick={() => setShowNotifications(!showNotifications)}
                aria-haspopup="true"
                aria-expanded={showNotifications}
                aria-label="View Notifications"
              >
                <FaBell />
                {/* Example Notification Badge */}
                {false && <span className="notification-badge">3</span>}
              </button>
            </Tooltip>
            {showNotifications && (
              <div className="notifications-dropdown" role="menu">
                <p>No new notifications.</p>
              </div>
            )}
          </div>

          {/* User Info */}
          <div className="user-info" ref={accountMenuRef}>
            <Tooltip content="Account Options">
              <motion.button
                className="account-btn"
                onClick={() => setShowAccountMenu(!showAccountMenu)}
                aria-haspopup="true"
                aria-expanded={showAccountMenu}
                aria-label="Account Options"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    alt={user?.displayName || "User"}
                    className="user-avatar"
                    onError={(e) => {
                      console.error("Error loading profile picture");
                      e.target.src = "/fallback-avatar.png"; // Ensure you have a fallback avatar image
                    }}
                  />
                ) : (
                  <FaUser className="user-avatar-placeholder" />
                )}
                <span className="user-name">{user?.displayName || "User"}</span>
              </motion.button>
            </Tooltip>

            {/* Account Dropdown Menu */}
            {showAccountMenu && (
              <div className="account-menu" role="menu">
                <Link
                  to="/account-settings"
                  className="account-menu-item"
                  role="menuitem"
                  onClick={() => setShowAccountMenu(false)}
                >
                  <FaUser className="menu-icon" /> Account Settings
                </Link>
                <button
                  onClick={signOut}
                  className="account-menu-item"
                  role="menuitem"
                >
                  <FaSignOutAlt className="menu-icon" /> Sign Out
                </button>
                <Link
                  to="/upgrade"
                  className="account-menu-item"
                  role="menuitem"
                  onClick={() => setShowAccountMenu(false)}
                >
                  <FaCreditCard className="menu-icon" /> Upgrade to Premium
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      <Modal isOpen={showTicTacToe} onClose={() => setShowTicTacToe(false)}>
        <TicTacToe onClose={() => setShowTicTacToe(false)} />
      </Modal>
    </header>
  );
};

export default Header;