// src/components/AuthProvider.js

import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { auth, db } from '../firebase'; // Changed 'firestore' to 'db'
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState({
    isPaid: false,
    dailyEntryCount: 0,
    lastEntryDate: null,
  });
  const [error, setError] = useState(null);

  // Check user subscription status in Firestore
  const checkUserSubscription = useCallback(async (uid) => {
    try {
      const subscriptionDoc = await getDoc(doc(db, 'subscriptions', uid)); // Changed 'firestore' to 'db'
      if (subscriptionDoc.exists()) {
        setSubscription(subscriptionDoc.data());
      } else {
        await setDoc(doc(db, 'subscriptions', uid), {
          isPaid: false,
          dailyEntryCount: 0,
          lastEntryDate: null,
        });
        setSubscription({
          isPaid: false,
          dailyEntryCount: 0,
          lastEntryDate: null,
        });
      }
    } catch (err) {
      console.error('Error checking subscription:', err);
      setError('Failed to fetch subscription data.');
    }
  }, []);

  // Sign in function with enhanced error handling and subscription check
  const signIn = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    try {
      const { user } = await signInWithPopup(auth, provider);
      setUser(user);
      await checkUserSubscription(user.uid);
    } catch (error) {
      console.error('Authentication error:', error);
      setError('Failed to authenticate. Please try again.');
    }
  }, [checkUserSubscription]);

  // Sign out function
  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      setSubscription({
        isPaid: false,
        dailyEntryCount: 0,
        lastEntryDate: null,
      });
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  }, []);

  // Listen to authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoading(true);
      if (user) {
        setUser(user);
        await checkUserSubscription(user.uid);
      } else {
        setUser(null);
        setSubscription({
          isPaid: false,
          dailyEntryCount: 0,
          lastEntryDate: null,
        });
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [checkUserSubscription]);

  // Provide the authentication state and functions to the rest of the app
  const value = useMemo(() => ({
    user,
    profilePicture: user?.photoURL || null,
    isLoading,
    signIn,
    signOut,
    subscription,
    error,
  }), [user, isLoading, signIn, signOut, subscription, error]);

  return (
    <AuthContext.Provider
      value={value}
    >
      {children}
    </AuthContext.Provider>
  );
};