import React, { useState, useContext, lazy, Suspense, startTransition } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, AuthContext } from "./components/AuthProvider";
import Header from "./components/Header";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import VersionToggle from "./components/v2/shared/VersionToggle";
import "./styles/App.css";

// Lazy load components
const HomePage = lazy(() => import("./components/HomePage"));
const CasesPage = lazy(() => import("./components/CasesPage"));
const UploadPage = lazy(() => import("./components/UploadPage"));
const UploadPageV2 = lazy(() => import("./components/v2/UploadPageV2"));
const Login = lazy(() => import("./components/Login"));
const PaymentForm = lazy(() => import("./components/PaymentForm"));
const BillingDetailsForm = lazy(() => import("./components/BillingDetailsForm"));
const CalendarPage = lazy(() => import("./components/CalendarPage"));
const CreateCase = lazy(() => import("./components/CreateCase"));
const IndividualCasePage = lazy(() => import("./components/IndividualCasePage"));
const Notifications = lazy(() => import("./components/Notifications"));
const AccountSettings = lazy(() => import("./components/AccountSettings"));
const UpgradePage = lazy(() => import("./components/UpgradePage"));

// Initialize Stripe
const stripePromise = loadStripe("your-publishable-key-here");

// Define a theme
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
  },
});

function App() {
  const { user, isLoading } = useContext(AuthContext);
  const [version, setVersion] = useState(1);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentAmount] = useState(20);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [pdfMetadata, setPdfMetadata] = useState(null);

  const handleBillingDetailsSubmit = (details) => {
    setBillingDetails(details);
    setShowBillingForm(false);
    startTransition(() => {
      setShowPaymentForm(true);
    });
  };

  const handlePaymentSuccess = async (subscriptionId) => {
    setSubscriptionStatus("active");
    setShowPaymentForm(false);
  };

  const handlePdfMetadata = (metadata) => {
    setPdfMetadata(metadata);
  };

  const handleVersionToggle = (newVersion) => {
    startTransition(() => {
      setVersion(newVersion);
    });
  };

  const ContactModal = () => (
    <div className="modal-overlay active" onClick={() => setShowContactModal(false)}>
      <div className="contact-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Contact Information</h2>
        <p>If you have any questions or need assistance, please reach out:</p>
        <p><strong>Name:</strong> Jackson</p>
        <p><strong>Phone:</strong> 813-321-6569</p>
        <p><strong>Email: </strong><a href="mailto:jackson@bertlegs.com">jackson@bertlegs.com</a></p>
        <button onClick={() => setShowContactModal(false)} className="close-btn">Close</button>
      </div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Login />
        </Suspense>
      </div>
    );
  }

  // Determine which upload component to render
  const UploadComponent = version === 2 ? UploadPageV2 : UploadPage;

  return (
    <Router>
      <div className="App">
        <Header />
        <main className="main-content">
          <Suspense fallback={<div className="loading-spinner"></div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/cases" element={<CasesPage />} />
              <Route 
                path="/upload" 
                element={
                  <>
                    <VersionToggle version={version} onToggle={handleVersionToggle} />
                    <UploadComponent 
                      onMetadataReceived={handlePdfMetadata}
                    />
                  </>
                } 
              />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/add-case" element={<CreateCase />} />
              <Route path="/case/:caseId" element={<IndividualCasePage metadata={pdfMetadata} />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route path="/upgrade" element={<UpgradePage />} />
            </Routes>
          </Suspense>
        </main>
        <footer className="footer">
          <div className="footer-content">
            <p>Docket4Me. All rights reserved.</p>
            <button onClick={() => setShowContactModal(true)} className="contact-btn">Contact Me</button>
            {subscriptionStatus && (
              <p className="subscription-status">Subscription Status: {subscriptionStatus}</p>
            )}
          </div>
        </footer>
        {showContactModal && <ContactModal />}
        {showBillingForm && (
          <div className="modal-overlay">
            <div className="billing-form-popup">
              <h2>Enter Billing Details</h2>
              <BillingDetailsForm onSubmit={handleBillingDetailsSubmit} />
              <button onClick={() => setShowBillingForm(false)} className="close-btn">&times;</button>
            </div>
          </div>
        )}
        {showPaymentForm && (
          <div className="modal-overlay">
            <div className="payment-popup">
              <h2>Upgrade to Unlimited Entries</h2>
              <p>Get unlimited docket entries for just ${paymentAmount}/month!</p>
              {paymentError && <p className="error">{paymentError}</p>}
              <Elements stripe={stripePromise}>
                <PaymentForm
                  amount={paymentAmount}
                  onSuccess={handlePaymentSuccess}
                  onError={(error) => {
                    setPaymentError(error.message);
                    setSubscriptionStatus("error");
                  }}
                  priceId="price_1Pyggb087SmWkm7ciZM4xSbT"
                  billingDetails={billingDetails}
                />
              </Elements>
              <button onClick={() => {
                setShowPaymentForm(false);
                setPaymentError(null);
              }} className="close-btn">&times;</button>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  );
}