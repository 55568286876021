// src/components/TicTacToe.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles/App.css"; 

const TicTacToe = ({ onClose }) => {
  const [ticTacToeBoard, setTicTacToeBoard] = useState(Array(9).fill(null));
  const [isPlayerNext, setIsPlayerNext] = useState(true);

  // Helper function to calculate the winner
  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  };

  // Minimax algorithm to find the best move for the computer
  const findBestMove = (board) => {
    let bestVal = -Infinity;
    let bestMove = -1;

    for (let i = 0; i < board.length; i++) {
      if (board[i] === null) {
        board[i] = "O";
        let moveVal = minimax(board, 0, false);
        board[i] = null;
        if (moveVal > bestVal) {
          bestMove = i;
          bestVal = moveVal;
        }
      }
    }
    return bestMove;
  };

  const minimax = (board, depth, isMaximizing) => {
    const winner = calculateWinner(board);
    if (winner === "O") return 10 - depth;
    if (winner === "X") return depth - 10;
    if (!board.includes(null)) return 0;

    if (isMaximizing) {
      let best = -Infinity;
      for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
          board[i] = "O";
          best = Math.max(best, minimax(board, depth + 1, false));
          board[i] = null;
        }
      }
      return best;
    } else {
      let best = Infinity;
      for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
          board[i] = "X";
          best = Math.min(best, minimax(board, depth + 1, true));
          board[i] = null;
        }
      }
      return best;
    }
  };

  const handleCellClick = (index) => {
    if (
      !isPlayerNext ||
      ticTacToeBoard[index] ||
      calculateWinner(ticTacToeBoard)
    )
      return;

    const newBoard = ticTacToeBoard.slice();
    newBoard[index] = "X";
    setTicTacToeBoard(newBoard);
    setIsPlayerNext(false);

    if (!calculateWinner(newBoard) && newBoard.includes(null)) {
      const bestMove = findBestMove(newBoard);
      setTimeout(() => {
        newBoard[bestMove] = "O";
        setTicTacToeBoard(newBoard);
        setIsPlayerNext(true);
      }, 500);
    } else {
      setIsPlayerNext(true);
    }
  };

  const resetGame = () => {
    setTicTacToeBoard(Array(9).fill(null));
    setIsPlayerNext(true);
  };

  const renderCell = (index) => (
    <button className="cell" onClick={() => handleCellClick(index)}>
      {ticTacToeBoard[index]}
    </button>
  );

  const winner = calculateWinner(ticTacToeBoard);
  const status = winner
    ? `Winner: ${winner}`
    : `Next player: ${isPlayerNext ? "X" : "O"}`;

  return (
    <div className="tic-tac-toe-overlay">
      <div className="tic-tac-toe-modal">
        <h3>Play Tic-Tac-Toe</h3>
        <div className="board">
          {[0, 1, 2].map((row) => (
            <div key={row} className="row">
              {[0, 1, 2].map((col) => renderCell(row * 3 + col))}
            </div>
          ))}
        </div>
        <div className="status">{status}</div>
        <div className="tic-tac-toe-actions">
          <button onClick={resetGame} className="reset-btn">
            Reset Game
          </button>
          <button onClick={onClose} className="close-btn">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

TicTacToe.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TicTacToe;